/**
 * @author Dylan TARDIF AMMOUR & Christophe LEDU
 * @description Détection du scroll pour mettre en évidence des éléments
*/

const handlePageScroll = {

    entries: {
        observer : [],
        observerTarget: [],
    },
    opts: {
        firstElementActive: false,
        intersectionObserver: {
            root: null,
            rootMargin: "0px 0px -75% 0px",
            threshold: 0.5
        }
    },

    /**
     * Init du script
     * @param {object} entries Contient les éléments à observer et les éléments cibles de l'intersection
     *
     * - observer | Doit être un tableau <!> Les éléments sur lesquels on observe l'intersection et qui vont être mis en évidence
     * - observerTarget | Les éléments cibles de l'intersection
     *
     * @param {function} callback Fonction de callback s'exécutant lorsque l'intersection est détectée
     *
     * @param {object} opts Contient les options
     *
     * - firstElementActive | Permet de définir si le premier élément doit être mis en évidence par défaut avec la class .active
     * - intersectionObserver | Options de l'intersection observer
     *      - root | Défini l'élément parent de l'intersection
     *      - rootMargin | Option d'intersection à 65% du bas du viewport
     *      - threshold | Visibilité de l'item = à 100% (0 : 0% / 1: 100%)
     *
     * @return void
     */
    init(entries, callback = false, opts = {}) {
        this.entries = { ...this.entries, ...entries};
        this.opts = { ...this.opts, ...opts};

        if(!this.entries.observer || !this.entries.observerTarget) {
            this.error('Handle Page Scroll - Empty entry, check your entries');
            return;
        }

        if(this.opts.firstElementActive) {
            this.entries.observer.at(0).classList.add('active'); // Ajout de la classe active au premier élément
        }

        const intersectionObserver = new IntersectionObserver(entries => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    for (item of this.entries.observer) {
                        item.classList.toggle('active', item.dataset.id === entry.target.id);
                       (callback && callback());
                    }
                }
            }
        }, this.opts.intersectionObserver);

        for (const entry of this.entries.observerTarget) {
            intersectionObserver.observe(entry);
        }
    },

    /**
     * Affiche un message d'erreur en console
     * @param {string} message | Le message d'erreur à afficher
     * @return void
     */
    error(message) {
        console.error(message);
    }
}